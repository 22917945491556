import React, { useState, useEffect } from 'react';
import { Select, Spin, Empty } from 'antd';
import { useAxiosGet } from '../configs/axios';
import debounceInput from '../function/debounceInput';
const RemoteSelect = ({
  placeholder,
  disabled,
  className,
  rtl,
  endpoint,
  mode,
  optionText,
  optionValue,
  exclude,
  onValueChange, //onChange function
  allowClear,
  valueOption, //Pass specific value for <Option value='ID'></Option>
  labelOption, // pass just label for <Option>{ label }</Option>
  filterFromServer,
  style,
  query, //* we using query while we need some params. depend backend developer.
  dependency, //* we need while we want re fetch and rebuilding UI
  defaultValue,
  inputValue
}) => {
  const [data, setData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [value, setValue] = useState(inputValue || undefined);

  const { request, error, loading } = useAxiosGet(endpoint, {
    limit: 100,
    offset: 0
  });

  useEffect(() => {
    if (endpoint) {
      loadData();
    }
  }, dependency || []);

  const onChange = (object) => {
    setValue(object);
    try {
      const key = object ? object.value : undefined;
      const objectLabel = apiData.find((d) => d[valueOption] == key);
      onValueChange({ key, object, objectLabel });
    } catch (e) {
      //
    }
  };

  const onSearch = (searchQuery) => {
    loadData(searchQuery);
  };

  const loadData = async (searchQuery) => {
    await request({
      limit: 100,
      offset: 0,
      q: searchQuery,
      search: searchQuery,
      ...query
    })
      .then((res) => {
        let array = [];
        if (exclude) {
          const filtered = res.filter(
            (value) => value[valueOption] !== exclude
          );
          array = filtered;
        } else {
          array = res;
          if (!Array.isArray(array)) {
            array = res;
          }
        }

        const options = [];
        array.forEach((element) => {
          options.push({
            label:
              typeof optionText == 'function'
                ? optionText(element)
                : element[labelOption],
            value:
              typeof optionValue == 'function'
                ? optionValue(element)
                : element[valueOption]
          });
        });
        setData(options);
        setApiData(array);
      })
      .catch((e) => {
        setData([]);
      });
  };

  const onSearchDebounce = debounceInput((e) => onSearch(e));

  return (
    <Select
      defaultValue={defaultValue}
      allowClear={allowClear}
      mode={mode || null}
      showSearch
      labelInValue
      disabled={disabled}
      value={value}
      size="large"
      notFoundContent={
        loading ? (
          <Spin size="small" />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      filterOption={
        !filterFromServer
          ? (input, option) => {
              return (option?.children ?? '')
                .toLowerCase()
                .includes(input.toLowerCase());
            }
          : false
      }
      onSearch={filterFromServer ? onSearchDebounce : undefined}
      onChange={onChange}
      style={style || {}}
      placeholder={placeholder || ''}
      className={className || undefined}
    >
      {data.map((val) => (
        <Select.Option
          key={val.value}
          value={val.value}
          style={{ fontFamily: rtl ? 'kurdishFont' : undefined }}
        >
          {val.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default RemoteSelect;
