import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Provider } from 'react-redux';
import store from './store';
import 'mapbox-gl/dist/mapbox-gl.css';
import { ConfigProvider } from 'antd';
import i18n from './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ConfigProvider direction={i18n.language === 'en' ? 'rtl' : 'ltr'}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </Provider>
);
