import { Drawer } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CloseCircleFilled } from '@ant-design/icons';
import i18n from '../i18n';

function DrawerDirection({
  title,
  onClose,
  open,
  children,
  width = 'calc(100% - 200px)',
  className = '',
  footer = undefined,
  closeIcon,
  bodyStyle,
  footerStyle,
  headerStyle
}) {
  const { t } = useTranslation();
  return (
    <Drawer
      className={className}
      closeIcon={
        closeIcon && (
          <CloseCircleFilled
            style={{
              color: '#7D8FA9',
              fontSize: 25,
              float: 'var(--right)'
            }}
          />
        )
      }
      title={title && <h3 style={{ color: '#fff' }}>{title}</h3>}
      key={`${title}-drawer`}
      open={open}
      onClose={onClose}
      width={width}
      destroyOnClose
      headerStyle={headerStyle}
      placement={i18n.language === 'en' ? 'right' : 'left'}
      footer={footer}
      bodyStyle={bodyStyle}
      footerStyle={footerStyle}
    >
      {children}
    </Drawer>
  );
}

export default DrawerDirection;

// calling import { CloseCircleFilled } from '@ant-design/icons';
// closeIcon={
//   <CloseCircleFilled
//     style={{
//       color: '#7D8FA9',
//       fontSize: 25,
//       float: 'var(--right)'
//     }}
//   />
// }
