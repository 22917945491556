import style from '../../../assets/styles/pages/homeVersions/store.module.scss';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  InputNumber,
  notification,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
  Tag
} from 'antd';
import { discountCalculate } from '../../../function/discountCalculate';
import { localStorageName, PaymentTypes } from '../../../configs/constants';
import useLocalStorage from '../../../configs/localStorage';
import MarketProductCard from './MarketProductCard';
import { useAxiosPost } from '../../../configs/axios';
import { handlePrintClick } from '../../../function/printOnClick';
import { Fragment, useState } from 'react';
import HowMuchPaidDrawer from './HowMuchPaidDrawer';
import ModalNote from './ModalNote';
import { FaTrashCan } from 'react-icons/fa6';
import RemoteSelect from '../../../utils/RemoteSelect';
import { ReactComponent as Money } from '../../../components.js/svg/money.svg';
import phone from '../../../assets/images/phone.png';

export default function ProductsAndCheckOut({ states, dispatch, activeTab }) {
  const { t } = useTranslation();
  const [showHowMuchPaidDrawer, setShowHowMuchPaidDrawer] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);

  const [auth] = useLocalStorage(localStorageName);
  const vendorInfo = auth?.user;

  let subtotal = 0;
  let totalPrice = 0;
  let discount = 0;

  const itemsArray = Object.values(states?.items || {});
  const sorted = itemsArray.sort((a, b) => {
    return b.sort - a.sort;
  });

  //Calculate Total Price
  sorted.forEach((item) => {
    subtotal += item.totalSingleProductPrice;

    // check discount per product;
    if (item.discount_type && item.discount_value && item.discount_amount) {
      discount += item.discount_amount;
    }
    // we saved totalPrice
  });

  totalPrice = subtotal - discount;

  // Calculate Discount for order
  if (states.discountType && states.discountValue) {
    discount += discountCalculate(
      totalPrice,
      states.discountType,
      states.discountValue
    );
    totalPrice = subtotal - discount;
  }

  // Calculate Discount for Customer
  if (
    states?.customerInfo?.discount_type &&
    states?.customerInfo?.discount_value &&
    states?.isSetCustomerDiscount
  ) {
    discount += discountCalculate(
      totalPrice,
      states.customerInfo.discount_type,
      states.customerInfo.discount_value
    );
    totalPrice = subtotal - discount;
  }

  // update the value of total price if have any discount or not.
  totalPrice = subtotal - discount;

  function resetStates() {
    dispatch({
      type: 'SET_STATE',
      changeStates: {
        discountType: '',
        discountValue: 0,
        customerInfo: {},
        isSetCustomerDiscount: false,
        partnerId: undefined,
        paymentType: 'cash',
        items: {}
      }
    });
  }

  const { request: placeOrderRequest, loading: orderPlacementLoading } =
    useAxiosPost('/orders');

  function placeOrder() {
    const body = {
      order_type: 'direct_sell',
      discount_type: states.discountType,
      discount_value: states.discountValue,
      order_payment_type: states.paymentType,
      customer_id: states?.customerInfo?.customer_id,
      customer_discount_value: states.isSetCustomerDiscount
        ? states.customerInfo.discount_value
        : 0,
      customer_discount_type: states.isSetCustomerDiscount
        ? states.customerInfo.discount_type
        : null,
      order_partner: states.partnerId,
      order_total: totalPrice,
      carts: [],
      order_instruction: states?.note
    };

    const checkItems = { ...states.items };

    Object.values(checkItems).map((item) => {
      item.quantity = item.quantity - (item?.numberGift || 0); // because we don't calculate the gift
      item.gift_quantity = item?.numberGift;
      body.carts.push(item);
    });

    placeOrderRequest(body).then((res) => {
      const qr = res?.qrCode;

      import('../../../components.js/print/templateCheckout').then(
        async (module) => {
          const getHtml = module.templateCheckout({
            data: body,
            vendor: vendorInfo,
            subtotal: subtotal,
            customer: states?.customerInfo?.customer_name,
            discount_amount: discount,
            qrCode: qr,
            orderId: res.orderId,
            currentUser: auth?.user?.user_name
          });
          handlePrintClick(getHtml);
          resetStates();
        }
      );
    });
  }

  const [formDiscount] = Form.useForm();

  return (
    <section
      style={{ width: '100%', padding: '0rem .5rem', minHeight: '80vh' }}
    >
      <div className={style['products-checkout-parent']}>
        <div className={style['market-products-container']}>
          {sorted?.map((item) => {
            return (
              <MarketProductCard
                style={style}
                key={item.product_id}
                product={item}
                dispatch={dispatch}
              />
            );
          })}
        </div>
        <div
          style={{
            backgroundColor: 'var(--eighth-color)',
            padding: '1rem',
            borderRadius: '1.2rem'
          }}
          className={style['cart-store-footer'] + ' ' + style['checkout-div']}
        >
          <Row
            style={{
              alignItems: 'center',
              marginBottom: '1rem'
            }}
          >
            <span
              style={{
                fontSize: '1rem',
                fontWeight: 'bold',
                letterSpacing: '0.05rem'
              }}
            >
              {t('tab')} #{activeTab}
            </span>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
              className={style['cart-store-header']}
            >
              <button
                style={{
                  display:
                    Object.keys(states.items)?.length > 0 ? 'flex' : 'none',
                  alignItems: 'center',
                  gap: '.5rem'
                }}
                onClick={() => {
                  resetStates();
                }}
              >
                <FaTrashCan
                  style={{
                    color: '#E56767',
                    fontWeight: 'bold',
                    fontSize: '1rem'
                  }}
                />
                <span style={{ color: '#E56767', fontSize: '1rem' }}>
                  {t('clear')}
                </span>
              </button>
            </div>
          </Row>
          <div className={style['market-order-total']}>
            <Row style={{ marginBottom: '12px' }}>
              <p style={{ color: '#6C7278' }}>{t('subtotal')}</p>
              <span>
                {subtotal?.toLocaleString()}
                {vendorInfo?.default_currency_code}
              </span>
            </Row>
            <Row>
              <p style={{ color: 'var(--red-color)' }}>{t('discount')}</p>
              <div
                style={{
                  color: 'var(--red-color)',
                  fontWeight: '700',
                  fontSize: '14px'
                }}
              >
                {discount?.toLocaleString()}
                {vendorInfo?.default_currency_code}
              </div>
            </Row>
            <hr />
            <Row className={style['cart-total']}>
              <p style={{ color: '#1A1C1E' }}>{t('total')}</p>
              <span>
                {totalPrice?.toLocaleString()}
                {vendorInfo?.default_currency_code}
              </span>
            </Row>
          </div>

          <Row style={{ gap: '0.5rem', alignItems: 'center' }}>
            <Col
              span={13}
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                alignItems: 'start'
              }}
            >
              <p>{t('customer')}</p>
              <RemoteSelect
                className={style['select']}
                labelForm={t('select_customer')}
                nameForm="customer_id"
                placeholder="Select customer"
                style={{ minWidth: '100%', fontSize: '0.8rem', color: 'black' }}
                allowClear
                filterFromServer={true}
                endpoint="/customers/list"
                valueOption="customer_id"
                defaultValue={states.customerId}
                optionText={(obj) => {
                  return (
                    <Fragment>
                      {obj?.customer_name}{' '}
                      {obj?.discount_value ? (
                        obj?.discount_type == 'percentage' ? (
                          <Tag color="red">
                            <Fragment>%{obj.discount_value}</Fragment>
                          </Tag>
                        ) : (
                          <Tag color="green">{obj.discount_value}</Tag>
                        )
                      ) : null}
                    </Fragment>
                  );
                }}
                onValueChange={({ objectLabel }) => {
                  dispatch({
                    type: 'SET_STATE',
                    changeStates: {
                      customerInfo: objectLabel
                    }
                  });
                }}
              />
            </Col>
            <Col
              span={10}
              style={{
                gap: '.5rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start'
              }}
            >
              <p>{t('partner')}</p>
              <RemoteSelect
                style={{
                  width: '100%'
                }}
                className={style['select']}
                endpoint="/partners/list"
                allowClear
                filterFromServer
                onValueChange={({ objectLabel }) => {
                  dispatch({
                    type: 'SET_STATE',
                    changeStates: {
                      partnerId: objectLabel
                        ? objectLabel.partner_id
                        : undefined
                    }
                  });
                }}
                valueOption="partner_id"
                labelOption="name"
                defaultValue={states.partnerId}
                query={{
                  offset: 0,
                  limit: 10
                }}
              />
            </Col>
          </Row>
          <Row style={{ display: 'flex', alignItems: 'center' }}>
            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                alignItems: 'start'
              }}
              span={13}
            >
              <p className="wh-clr">{t('discount')}</p>
              <Popover
                open={openPopover}
                className={style['checkout-popover']}
                placement="top"
                title={t('discount')}
                trigger="click"
                onOpenChange={(set) => setOpenPopover(set)}
                content={
                  <Form
                    form={formDiscount}
                    layout="vertical"
                    onFinish={(values) => {
                      setOpenPopover(false);
                      if (values.discount_type) {
                        dispatch({
                          type: 'SET_STATE',
                          changeStates: {
                            discountType: values.discount_type,
                            discountValue: values.discount_value
                          }
                        });
                      } else {
                        notification.warning({
                          message: 'Please Select Discount Type'
                        });
                      }
                    }}
                  >
                    <Row
                      style={{
                        width: '200px'
                      }}
                    >
                      <Col span={24}>
                        <Form.Item name="discount_type">
                          <Select
                            placeholder="Discount type"
                            options={[
                              {
                                label: t('percentage_discount'),
                                value: 'percentage'
                              },
                              {
                                label: t('fixed_discount'),
                                value: 'fixed'
                              }
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          name="discount_value"
                          rules={[
                            {
                              message: t('required'),
                              required: true
                            },
                            {
                              validator: (_, value) => {
                                return new Promise((resolve, reject) => {
                                  const type =
                                    formDiscount.getFieldValue('discount_type');
                                  if (!type) {
                                    notification.warning({
                                      message: t('please_select_discount_type')
                                    });
                                    reject(new Error());
                                  } else if (type === 'fixed') {
                                    resolve();
                                  } else if (
                                    type === 'percentage' &&
                                    value <= 100
                                  ) {
                                    resolve();
                                  } else {
                                    notification.warning({
                                      message: `${t('maximum')} ${
                                        type === 'fixed'
                                          ? value.toLocaleString()
                                          : '100%'
                                      }`
                                    });
                                    reject();
                                  }
                                });
                              }
                            }
                          ]}
                        >
                          <InputNumber
                            style={{ color: 'white' }}
                            min={0}
                            placeholder={t('amount')}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item>
                          <Button
                            htmlType="submit"
                            style={{
                              background: 'var(--light-green-color)',
                              borderWidth: 0,
                              width: '100%'
                            }}
                          >
                            {t('save')}
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                }
              >
                <Button
                  style={{
                    width: '100%',
                    color: 'black'
                  }}
                >
                  {t('discount')}
                </Button>
              </Popover>
            </Col>

            <Col
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                alignItems: 'start'
              }}
              span={10}
            >
              <p>{t('auto_apply')}</p>
              <Switch
                onChange={(e) => {
                  dispatch({
                    type: 'SET_STATE',
                    changeStates: {
                      isSetCustomerDiscount: e
                    }
                  });
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: 'flex',
                alignItems: 'start',
                gap: '0.5rem',
                flexDirection: 'column'
              }}
              span={24}
            >
              <p>{t('paymentType')}</p>
              {/* <Select
                defaultValue={states.paymentType}
                className={style['select']}
                allowClear={true}
                size="large"
                onChange={(value) => {
                  dispatch({
                    type: 'SET_STATE',
                    changeStates: {
                      paymentType: value
                    }
                  });
                }}
                style={{ width: '100%' }}
              >
                <Select.Option value="cash">{t('cash')}</Select.Option>
                <Select.Option value="online">{t('online')}</Select.Option>
              </Select> */}

              <Row style={{ width: '100%' }} justify={'space-between'}>
                <Col
                  onClick={() => {
                    dispatch({
                      type: 'SET_STATE',
                      changeStates: {
                        paymentType: PaymentTypes.Cash
                      }
                    });
                  }}
                  span={11}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    gap: '2px',
                    border: '3px solid',
                    borderColor:
                      states.paymentType === PaymentTypes.Cash
                        ? 'var(--light-green-color)'
                        : 'white',
                    backgroundColor: 'white',
                    padding: '0.5rem',
                    borderRadius: '0.5rem',
                    transition: 'all 200ms'
                  }}
                >
                  <Money />
                  <span
                    style={{
                      fontSize: '1rem',
                      letterSpacing: '-0.28px',
                      fontWeight: '500',
                      color: 'black'
                    }}
                  >
                    {t('cash')}
                  </span>
                </Col>
                <Col
                  span={11}
                  onClick={() => {
                    dispatch({
                      type: 'SET_STATE',
                      changeStates: {
                        paymentType: PaymentTypes.Online
                      }
                    });
                  }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    gap: '2px',
                    border: '3px solid',
                    borderColor:
                      states.paymentType === PaymentTypes.Online
                        ? 'var(--light-green-color)'
                        : 'white',
                    backgroundColor: 'white',
                    padding: '0.5rem',
                    borderRadius: '0.5rem',
                    transition: 'all 200ms'
                  }}
                >
                  <img src={phone} style={{ height: '1rem', width: '1rem' }} />
                  <span
                    style={{
                      fontSize: '1rem',
                      letterSpacing: '-0.28px',
                      fontWeight: '500',
                      color: 'black'
                    }}
                  >
                    {t('online')}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <div
            style={{ marginTop: '3rem', marginBottom: '1rem' }}
            className={style['note']}
          >
            <div>
              <svg
                width="16"
                height="15"
                viewBox="0 0 16 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1875 0.59375H1.81251C1.43955 0.59375 1.08187 0.741908 0.818143 1.00563C0.55442 1.26935 0.406262 1.62704 0.406262 2V13.25C0.405139 13.5178 0.481203 13.7803 0.625353 14.006C0.769503 14.2317 0.975632 14.4111 1.21907 14.5227C1.40595 14.6101 1.60969 14.6557 1.81603 14.6562C2.14623 14.6554 2.46551 14.5378 2.71743 14.3244L2.72587 14.3166L4.93931 12.4062H14.1875C14.5605 12.4062 14.9182 12.2581 15.1819 11.9944C15.4456 11.7306 15.5938 11.373 15.5938 11V2C15.5938 1.62704 15.4456 1.26935 15.1819 1.00563C14.9182 0.741908 14.5605 0.59375 14.1875 0.59375ZM13.9063 10.7188H4.62501C4.4225 10.7184 4.22671 10.7913 4.07376 10.9241L2.09376 12.6334V2.28125H13.9063V10.7188Z"
                  fill="white"
                />
              </svg>
              <p>{states?.note ? states.note : t('any_special_instruction')}</p>
            </div>
            <button onClick={() => setShowNoteModal(true)}>
              {t(states?.note ? 'edit_note' : 'add_note')}
            </button>
          </div>

          <button
            disabled={orderPlacementLoading}
            className={style['btn-place-order']}
            onClick={() => {
              let permission = false;
              for (let i = 0; i < (auth?.permissions || []).length; i++) {
                const per = auth?.permissions[i];
                if (per.permission_name == 'calculator_in_order') {
                  permission = true;
                }
              }
              if (permission) {
                setShowHowMuchPaidDrawer(true);
              } else {
                placeOrder();
              }
            }}
          >
            {orderPlacementLoading ? <Spin /> : t('place_order')}
          </button>
        </div>
      </div>

      <HowMuchPaidDrawer
        open={showHowMuchPaidDrawer}
        setOpen={setShowHowMuchPaidDrawer}
        placeOrder={placeOrder}
        totalPrice={totalPrice}
        orderPlacementLoading={orderPlacementLoading}
      />
      <ModalNote
        dispatch={dispatch}
        open={showNoteModal}
        onDrawerClose={() => {
          setShowNoteModal(false);
        }}
        note={states?.note}
      />
    </section>
  );
}
