import { Row, Space, Image } from 'antd';
import TrashSvg from '../../../components.js/svg/TrashSvg';
import { useReducer } from 'react';
import DetailProduct from '../../../components.js/checkoutPage/cashier/DetailProduct';
import { FaMinus } from 'react-icons/fa6';

export default function MarketProductCard({ product, style, dispatch }) {
  const initialState = {
    openDiscountPopover: false,
    openGiftPopover: false,
    openDetailDrawer: false,
    singleCard: {}
  };

  const [statesSelectedCard, changeStates] = useReducer(
    (currentVal, newVal) => ({
      ...currentVal,
      ...newVal
    }),
    initialState
  );
  return (
    <div
      style={{ backgroundColor: '#35394B', height: 'fit' }}
      className={style['single-card']}
      key={product.product_id}
    >
      <Row
        justify="space-between"
        wrap="nowrap"
        className={style['single-card-header']}
      >
        <Space>
          <Image
            className={style['single-card-img']}
            src={
              product.product_image ||
              'https://lezzooeats-uploads.s3.us-east-2.amazonaws.com/custom/%3F.webp'
            }
          />
          <Space direction="vertical">
            <p style={{ fontSize: '1rem' }}>{product.product_name}</p>
            <span style={{ fontSize: '.8rem' }}>
              {product.price?.toLocaleString()} IQD
            </span>
          </Space>
        </Space>
      </Row>
      <Row style={{ gap: '6px' }}>
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '40px',
            padding: '.2rem'
          }}
        >
          <button
            className={style['decrease-quantity-button']}
            onClick={() => {
              dispatch({
                type: 'DECREASE_QTY',
                product: product
              });
            }}
          >
            {product.quantity == 1 ? <TrashSvg /> : <FaMinus />}
          </button>
        </div>
        <input
          className={style['quantity-input']}
          onChange={(e) => {
            if (e.target.value < 1) {
              e.target.value = 1;
            }
            dispatch({
              type: 'SET_QUANTITY',
              product: product,
              quantity: +e.target.value
            });
          }}
          min={1}
          type="number"
          value={product.quantity}
        />
        <button
          style={{
            backgroundColor: 'var(--light-green-color)',
            borderRadius: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          onClick={() => {
            dispatch({
              type: 'INCREASE_QTY',
              product: product
            });
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 3.75V14.25M3.75 9H14.25"
              stroke="#1F1D2B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </Row>
      {/* <Row
        justify={'start'}
        align={'middle'}
        style={{ gap: '.5rem', flexDirection: 'row', alignItems: 'center' }}
      >
        <Col sm={1}>
          <label style={{ color: 'white', fontSize: '1.5rem' }}>x</label>
        </Col>
        <Col sm={20} lg={5}>
          <input
            style={{ outline: 'none', width: '100%' }}
            onChange={(e) => {
              if (e.target.value < 1) {
                e.target.value = 1;
              }
              dispatch({
                type: 'SET_QUANTITY',
                product: product,
                quantity: +e.target.value
              });
            }}
            min={1}
            type="number"
            value={product.quantity}
          />
        </Col>
      </Row> */}

      {statesSelectedCard.openDetailDrawer ? (
        <DetailProduct
          singleProduct={statesSelectedCard.singleCard}
          open={statesSelectedCard.openDetailDrawer}
          onDrawerClose={() => {
            changeStates({
              openDetailDrawer: false,
              singleCard: {}
            });
          }}
        />
      ) : null}
    </div>
  );
}
