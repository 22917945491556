import { Col, Row } from 'antd';
import DebounceSelect from '../../../utils/DebounceSelect';
import style from '../../../assets/styles/pages/homeVersions/store.module.scss';
import { useTranslation } from 'react-i18next';
import { useAxiosGet } from '../../../configs/axios';
import { Fragment, useEffect, useState } from 'react';
import ShiftDrawer from './ShiftDrawer';
import { FaPlus, FaUserClock } from 'react-icons/fa6';
import i18n from '../../../i18n';

const initialStateObj = {
  discountType: '',
  discountValue: 0,
  customerInfo: {},
  isSetCustomerDiscount: false,
  partnerId: undefined,
  paymentType: 'cash',
  items: {},
  showLezzooOrderDrawer: false,
  singleOrderLezzoo: {},
  note: ''
};

export default function TabsSection({ dispatch, setActiveTab, states }) {
  const { t } = useTranslation();

  const [showStartShift, setShowStartShift] = useState(false);

  const tabsData = JSON.parse(localStorage.getItem('tabs'));
  const [tabs, setTabs] = useState(
    tabsData ??
      Array.from({ length: 1 }, (_, i) => {
        return {
          key: i + 1,
          count: 0
        };
      })
  );

  useEffect(() => {
    localStorage.setItem(
      'tabs',
      JSON.stringify(tabs.filter((tab) => tab.count > 0 || tab.key === 1))
    );
  }, [tabs]);

  const [selectedTab, setSelectedTab] = useState(1);

  const { request: fetchProductListInInput } = useAxiosGet(
    '/products/list?limit=20&offset=0'
  );

  const { data: dataRegister, request: getShiftData } = useAxiosGet(
    '/registers',
    {
      autoRun: true
    }
  );

  useEffect(() => {
    const upToDateTabData = localStorage.getItem('tab' + selectedTab);
    if (upToDateTabData) {
      dispatch({
        type: 'SET_STATE',
        changeStates: JSON.parse(upToDateTabData)
      });
    } else {
      localStorage.setItem(
        'tab' + selectedTab,
        JSON.stringify(initialStateObj)
      );
      dispatch({
        type: 'SET_STATE',
        changeStates: initialStateObj
      });
    }
  }, [selectedTab]);

  useEffect(() => {
    setActiveTab(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    localStorage.setItem('tab' + selectedTab, JSON.stringify(states));

    const storedTabs = tabs.map((tab) => {
      const tabData = JSON.parse(localStorage.getItem('tab' + tab.key));
      return {
        key: tab.key,
        count: Object.values(tabData?.items ?? {}).length
      };
    });
    setTabs(storedTabs);
  }, [states]);

  const isRTL = i18n.language === 'en';

  return (
    <Fragment>
      <div style={{ height: '100%' }} className={style['main-part']}>
        <Row
          style={{
            paddingRight: isRTL ? '1rem' : '0rem',
            rowGap: '1rem'
          }}
        >
          <Col
            style={{ paddingRight: isRTL ? '0rem' : '1.5rem' }}
            sm={24}
            lg={19}
            xl={20}
          >
            <DebounceSelect
              style={{ width: isRTL ? '94%' : '96%' }}
              fetchOptions={fetchProductListInInput}
              optionValue="product_id"
              placeholder={t('search_by_barcode_or_product_name')}
              onListClick={(prod) => {
                if (prod) {
                  dispatch({
                    type: 'ADD_CART',
                    product: prod
                  });
                }
              }}
              dataOfFetch={(data) => {
                if (data?.length) {
                  dispatch({
                    type: 'ADD_CART',
                    product: data[0]
                  });
                }
              }}
            />
          </Col>
          <Col sm={24} lg={5} xl={4}>
            <div
              style={{
                width: '100%'
              }}
            >
              <button
                className={style['btn-start-end-shift']}
                onClick={() => {
                  setShowStartShift(true);
                }}
              >
                <div
                  style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
                >
                  <FaUserClock style={{ fontSize: '1.3rem' }} />
                  {Object.keys(dataRegister || {})?.length
                    ? t('close_shift')
                    : t('start_shift')}
                </div>
              </button>
            </div>
          </Col>

          <Row
            style={{ gap: isRTL ? '1.5rem' : '4.3rem' }}
            className={style['market-tabs-section']}
          >
            <Col sm={14} lg={18} xl={19} className={style['tabs-section']}>
              {tabs.map((tab) => {
                return (
                  <div
                    onClick={() => setSelectedTab(tab.key)}
                    style={{
                      backgroundColor:
                        tab.key === selectedTab ? 'var(--red-color)' : 'white',
                      borderRadius: '.5rem',
                      padding: '.5rem 3rem',
                      cursor: 'pointer',
                      color:
                        selectedTab === tab.key ? 'white' : 'var(--main-color)',

                      transition: 'all .2s',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column'
                    }}
                    key={tab.key}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '18px',
                        fontWeight: 'bold'
                      }}
                    >
                      <span>{t('tab')}</span>
                      <span>{tab.key}</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '2px'
                      }}
                    >
                      <span style={{ fontWeight: 500 }}>{tab.count}</span>
                      <span
                        style={{
                          opacity: '.7'
                        }}
                      >
                        {t('items')}
                      </span>
                    </div>
                  </div>
                );
              })}
            </Col>
            <Col
              sm={8}
              lg={6}
              xl={5}
              style={{
                display: 'flex',
                justifyContent: isRTL ? 'flex-end' : 'flex-start'
              }}
            >
              <div
                onClick={() => {
                  setTabs([...tabs, { key: tabs.length + 1, count: 0 }]);
                }}
                className={style['add-tab-button']}
              >
                <FaPlus />
                <span>{t('addNewTab')}</span>
              </div>
            </Col>
          </Row>
        </Row>
      </div>
      {showStartShift ? (
        <ShiftDrawer
          open={showStartShift}
          setShowStartShift={setShowStartShift}
          isStarted={!!Object.keys(dataRegister || {})?.length}
          getShiftData={getShiftData}
        />
      ) : null}
    </Fragment>
  );
}
