import style from '../../../assets/styles/pages/homeVersions/store.module.scss';
import { Fragment, useState, useReducer, useEffect } from 'react';
import TabsSection from './TabsSection';
import CheckoutPart from './CheckoutPart';
import ProductsAndCheckOut from './ProductsAndCheckout';
import { discountCalculate } from '../../../function/discountCalculate';
import OrderLezzooDrawer from './OrderLezzooDrawer';
import { useAxiosGet } from '../../../configs/axios';
import { useTranslation } from 'react-i18next';
import { Badge, notification, Row, Spin } from 'antd';
import alertGif from '../../../assets/images/alert.gif';
import LezzoOrders from '../../../components.js/orderManagement/LezzooOrders';
import { useSocketContext } from '../../../socket/SocketContext';
import {
  incrementLezzooOrdersCount,
  lezzooOrdersCountSelector,
  setLezzooOrdersCount
} from '../../../store/ordersSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { LEZZOOPARTNERID } from '../../../configs/constants';

const initialStateObj = {
  discountType: '',
  discountValue: 0,
  customerInfo: {},
  isSetCustomerDiscount: false,
  partnerId: undefined,
  paymentType: 'cash',
  items: {},
  showLezzooOrderDrawer: false,
  singleOrderLezzoo: {},
  note: ''
};

export default function MarketView() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(1);
  const [lezzooOrder, setLezzooOrder] = useState({
    open: false,
    lezzooOrder: null
  });
  const currentTab = localStorage.getItem('tab' + activeTab);

  const [initialState] = useState(JSON.parse(currentTab) ?? initialStateObj);

  const [states, dispatch] = useReducer(reducer, initialState);

  const dispatchStore = useDispatch();

  const {
    data: dataOrderLezzoo,
    request: getLezzooOrders,
    loading: getLezzooOrderLoading
  } = useAxiosGet('/orders/lezzooOrders', {
    autoRun: true
  });

  const { data: lezzooOrderCountData, loading: getLezzooOrderCountLoading } =
    useAxiosGet('/orders/lezzooOrderCounts', {
      offset: 0,
      autoRun: true
    });

  const { request: requestVendorList } = useAxiosGet(
    '/vendors/get_user_vendors',
    {
      autoRun: true
    }
  );

  useEffect(() => {
    dispatchStore(setLezzooOrdersCount(lezzooOrderCountData));
  }, [lezzooOrderCountData]);

  const lezzooOrdersCount = useSelector(lezzooOrdersCountSelector);
  const socket = useSocketContext();

  useEffect(() => {
    if (socket) {
      socket.on('orderCreation', (data) => {
        if (data.order.order_partner === LEZZOOPARTNERID) {
          if (!lezzooOrder.open) {
            dispatchStore(incrementLezzooOrdersCount());
            notification.success({
              message: t('newOrder'),
              description: t('newOrderDesc'),
              placement: 'top',
              duration: 10
            });
          }
        }
      });
      return () => {
        socket.off('orderCreation');
      };
    }
  }, [socket, lezzooOrder.open]);

  return (
    <Fragment>
      <section>
        <Row align={'middle'} className={style['title-container']}>
          <Badge
            count={
              getLezzooOrderCountLoading ? (
                <Spin size="small" />
              ) : (
                lezzooOrdersCount || 0
              )
            }
            onClick={() => setLezzooOrder({ open: true })}
            showZero
            color="#FF555C"
          >
            <img
              style={{
                height: 40,
                cursor: 'pointer'
              }}
              src={alertGif}
            />
          </Badge>

          <h3 className={style['title']}>{t('LezzooOrders')}</h3>
        </Row>

        <section
          className={style['product-part']}
          style={{
            '--height-product-part': dataOrderLezzoo?.orders ? '90%' : '100%'
          }}
        >
          <TabsSection
            dispatch={dispatch}
            isGrocery={true}
            setActiveTab={setActiveTab}
            states={states}
          />
          {/* <CheckoutPart dispatch={dispatch} states={states} /> */}
        </section>
      </section>

      <ProductsAndCheckOut
        dispatch={dispatch}
        states={states}
        isGrocery={true}
        activeTab={activeTab}
      />

      {states.showLezzooOrderDrawer ? (
        <OrderLezzooDrawer
          open={states.showLezzooOrderDrawer}
          singleOrderLezzoo={states.singleOrderLezzoo}
          dispatch={dispatch}
          getLezzooOrders={getLezzooOrders}
        />
      ) : null}

      {lezzooOrder.open ? (
        <LezzoOrders
          onClose={() => {
            setLezzooOrder({ open: false });
          }}
        />
      ) : null}
    </Fragment>
  );
}

/**
 * For product I added total price
 */
function reducer(state, action) {
  switch (action.type) {
    case 'SET_STATE':
      return {
        ...state,
        ...action.changeStates
      };
    case 'ADD_CART':
      addCart(state, action);
      return {
        ...state
      };
    case 'INCREASE_QTY':
      increaseQty(state, action);
      return {
        ...state
      };
    case 'SET_QUANTITY':
      setQuantity(state, action);
      return {
        ...state
      };
    case 'DECREASE_QTY':
      decreaseQty(state, action);
      return {
        ...state
      };
    case 'SET_DISCOUNT_PER_PRODUCT':
      setDiscountPerProduct(state, action);
      return {
        ...state
      };
    case 'SET_GIFT_PER_PRODUCT':
      setGiftPerProduct(state, action);
      return {
        ...state
      };
    case 'SET_NOTE':
      setNote(state, action);
      return {
        ...state
      };
    default:
      return state;
  }
}

function calculateTotalPrice(quantity, price) {
  return quantity * price;
}

function addCart(state, action) {
  const product_id = action?.product?.product_id;

  const singleItem = state.items[product_id];
  if (product_id in state.items) {
    // Add quantity by 1 if the product ID is contain
    singleItem.quantity = (singleItem.quantity || 0) + 1;
    singleItem.totalSingleProductPrice = calculateTotalPrice(
      singleItem.quantity,
      singleItem.price
    );
  } else {
    // Set quantity to 1 if the product ID is not contain
    const sort = Object.keys(state.items || {}).length + 1; // put sort for each product when added for sorting in cartStore.jsx.
    state.items[product_id] = {
      ...action.product,
      quantity: 1,
      totalSingleProductPrice: action.product.price,
      sort: sort
    };
  }
}

function increaseQty(state, action) {
  const numberGift = action?.product?.numberGift;
  const product_id = action?.product?.product_id;
  const singleItem = state.items[product_id];
  const qty = (singleItem.quantity || 0) + 1;
  singleItem.quantity = qty;
  const checkGiftQty = qty - (numberGift || 0);
  singleItem.totalSingleProductPrice = calculateTotalPrice(
    checkGiftQty,
    singleItem.price
  );
}

function setQuantity(state, action) {
  const numberGift = action?.product?.numberGift;
  const product_id = action?.product?.product_id;
  const singleItem = state.items[product_id];
  const qty = action.quantity;
  singleItem.quantity = qty;
  const checkGiftQty = qty - (numberGift || 0);
  singleItem.totalSingleProductPrice = calculateTotalPrice(
    checkGiftQty,
    singleItem.price
  );
}

function decreaseQty(state, action) {
  const quantity = action?.product?.quantity;
  const product_id = action?.product?.product_id;
  const numberGift = action?.product?.numberGift;
  const singleItem = state.items[product_id];

  if (quantity == 1) {
    delete state.items[product_id];
  } else {
    const qty = (quantity || 0) - 1;
    singleItem.quantity = qty;
    if (qty < numberGift) {
      singleItem.numberGift = qty;
    }

    const checkGiftQty = qty - (singleItem?.numberGift || 0);

    singleItem.totalSingleProductPrice = calculateTotalPrice(
      checkGiftQty,
      singleItem.price
    );
  }
}

function setDiscountPerProduct(state, action) {
  const { discountValue, product, discountType } = action;
  const { product_id, totalSingleProductPrice } = product;

  const singleItem = state.items[product_id];
  const discountSinglePrice = discountCalculate(
    totalSingleProductPrice,
    discountType,
    discountValue
  );

  singleItem.discount_amount = discountSinglePrice;
  singleItem.discount_type = discountType;
  singleItem.discount_value = discountValue;
}

function setGiftPerProduct(state, action) {
  const { product, numberGift } = action;
  const { product_id, quantity } = product;
  const singleItem = state.items[product_id];
  const checkGiftQty = quantity - (numberGift || 0);
  singleItem.numberGift = numberGift;
  singleItem.totalSingleProductPrice = calculateTotalPrice(
    checkGiftQty,
    singleItem.price
  );
}

function setNote(state, action) {
  const { note } = action;
  state.note = note;
}
