//! DEV
const API_URL = process.env.REACT_APP_API_URL;
export const API_SOCKET = process.env.REACT_APP_API_SOCKET;

export const localStorageName = 'pos-lezzoo-auth';
export const languageStorageName = 'pos-lezzoo-lang';

// This should not be hardcoded, we need to use another way
export const LEZZOOPARTNERID = 5;

export const dateTimeFormat12 = 'YYYY-MM-DD hh:mm:ss A';
export const dateTimeFormat24 = 'YYYY-MM-DD hh:mm:ss';

export const orderTypeColors = {
  pending: '#FFCBD5',
  in_the_kitchen: '#B7E0F8',
  preparing: '#FFF4BB',
  prepared: 'var(--light-green-color)',
  serving: 'var(--light-green-color)'
};

export const daysOfWeek = [
  'saturday',
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday'
];

export const colors = [
  '#CFDDDB',
  '#E4CCED',
  '#C2DBE9',
  '#CACAEE',
  '#C2E9DD',
  '#F0C8CF',
  '#E6DADE',
  '#F9C2D9',
  '#A9C2A9',
  '#CFDDDB',
  '#E4CCED',
  '#C2DBE9',
  '#CACAEE',
  '#C2E9DD',
  '#F0C8CF',
  '#E6DADE',
  '#F9C2D9',
  '#A9C2A9'
];

export const languages = [
  {
    id: 1,
    lang: 'en',
    label: 'English'
  },
  {
    id: 2,
    lang: 'ar',
    label: 'Arabic'
  },
  {
    id: 3,
    lang: 'kr',
    label: 'Kurdish'
  },
  {
    id: 4,
    lang: 'bd',
    label: 'Sorani'
  }
];

/**
 * Table Status
 * @type {{Busy: "busy", Available: "available"}}
 */
export const TableStatuses = {
  Busy: 'busy',
  Available: 'available'
};

export default API_URL;

export const printingOptions = [
  { value: 'order_created', label: 'orderCreated' },
  { value: 'order_prepared', label: 'orderPrepared' },
  { value: 'order_completed', label: 'orderCompleted' },
  { value: 'order_cancelled', label: 'orderCancelled' }
];

export const PrintingPrefsEnum = {
  ORDER_CREATED: 'order_created',
  ORDER_PREPARED: 'order_prepared',
  ORDER_COMPLETED: 'order_completed',
  ORDER_CANCELLED: 'order_cancelled'
};

export const kitchenTypes = [
  {
    value: 'single_kitchen',
    label: 'singleKitchen'
  },
  {
    value: 'multi_kitchen',
    label: 'multiKitchen'
  },
  {
    value: 'no_kitchen',
    label: 'noKitchen'
  }
];

export const KitchenTypesEnum = {
  SINGLE_KITCHEN: 'single_kitchen',
  MULTI_KITCHEN: 'multi_kitchen',
  NO_KITCHEN: 'no_kitchen'
};

export const OrderStatusesEnum = {
  Pending: 'pending',
  InTheKitchen: 'in_the_kitchen',
  Preparing: 'preparing',
  Prepared: 'prepared',
  Serving: 'serving',
  Completed: 'completed',
  Cancelled: 'canceled'
};

export const UserRolesEnum = {
  SuperAdmin: 'super_admin',
  Kitchen: 'kitchen',
  Cashier: 'cashier',
  Waiter: 'waiter',
  Accountant: 'accountant'
};

export const VendorTypesEnum = {
  Resturant: 'resturant',
  FastCasual: 'fastcasual',
  Store: 'store',
  Market: 'market'
};

export const DineInKitchenStatuses = [
  {
    order_status_code: 'in_the_kitchen',
    order_status: 'In The kitchen',
    sort: 1
  },
  {
    order_status_code: 'preparing',
    order_status: 'Preparing',
    sort: 2
  },
  {
    order_status_code: 'prepared',
    order_status: 'Prepared',
    sort: 3
  },
  {
    order_status_code: 'serving',
    order_status: 'Serving',
    sort: 4
  },
  {
    order_status_code: 'completed',
    order_status: 'Completed',
    sort: 5
  }
];

export const DineInNoKitchenStatuses = DineInKitchenStatuses.filter(
  (status) => status.order_status_code !== 'in_the_kitchen'
);

export const PaymentTypes = {
  Online: 'online',
  Cash: 'cash'
};
